<template>
  <v-row class="basic-info pl-2 d-flex justify-end" v-if="member">
    <template v-if="hasBasicInfo">
      <v-col class="card pt-0" cols="1">
        <p class="card-title mb-2">{{ $t("gender") }}</p>
        <p class="card-body">
          {{ this.member.gender == 1 ? $t("male") : "" }}
        </p>
        <p class="card-body">
          {{ this.member.gender == 2 ? $t("female") : "" }}
        </p>
      </v-col>
      <v-col class="card pt-0" cols="1">
        <p class="card-title mb-2">{{ $t("age") }}</p>
        <p class="card-body">{{ this.member.age }}</p>
      </v-col>
    </template>

    <v-col
      class="card pt-0"
      :class="{ 'offset-5': isLifeChamps }"
      cols="10"
      v-if="routesWithIntervenedBtn.includes($route.name)"
    >
      <v-btn
        @click="toggleIntervened"
        hide-details
        class="
              pt-0
              pb-0
              text-uppercase
              intervened
              d-flex
              align-center
              text-center
              float-right
              bg-theme-primary
            "
        :class="{
          'mark-as-intervened': !isIntervened,
          'white--text': isLifeChamps,
        }"
        v-if="member"
      >
        <v-icon v-if="isIntervened">mdi-checkbox-marked</v-icon>
        <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        <span class="label">
          {{ isIntervened ? $t("intervened") : $t("mark_as_intervened") }}
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "BasicInfo",

  props: {
    isLifeChamps: {
      type: Boolean,
      default: false,
    },
    member: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      routesWithIntervenedBtn: ["members-profile", "detailed-view"],
    };
  },

  computed: {
    isIntervened: {
      get() {
        return this.member && this.member.is_intervened;
      },
      set(val) {
        this.member.is_intervened = val;
      },
    },
    hasBasicInfo: function () {
     return this.$store.getters["auth/hasBasicInfo"];
    },
  },

  methods: {
    toggleIntervened() {
      axios
        .post("/dashboard/users/update-intervened", {
          user_id: this.$route.params.id,
          intervened: !this.isIntervened,
        })
        .then((res) => {
          this.isIntervened = !this.isIntervened;
          this.getQuestionnaires();
          this.$toasted.success(this.$t("member_intervened_succesfully"));

          this.$store.dispatch("logActivity", {
            tag: "update_intervened",
            type: 1,
            reference_id: this.$route.params.id,
          });
        })
        .catch((err) => this.$toasted.error(err?.response?.data?.data[0]));
    },
  },
};
</script>
