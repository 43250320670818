<template>
	<div class="members-profile">
		<v-container class="first-part">
			<Breadcrumbs />

			<v-row>
				<v-col class="d-flex pb-0 mb-12">
					<v-icon
						large
						:color="
							this.$store.getters['auth/user']
								? this.$store.getters['auth/user'].theme_config.primary_color
								: ''
						"
						>mdi-account-question</v-icon
					>
					<h1 class="d-flex ml-2 page-title pt-3">
						{{ $t("questionnaires") }}
					</h1>
				</v-col>
			</v-row>

			<BasicInfo :isLifeChamps="isLifeChamps" :member="member" />
		</v-container>

		<v-container
			fluid
			:class="`medical-info-container ${isLifeChamps ? 'mt-8' : 'mt-16'}`"
		>
			<v-container>
				<v-tabs v-model="tab" align-with-title class="mb-7 custom-tabs">
					<v-tabs-slider
						:color="
							$store.getters['auth/user']
								? $store.getters['auth/user'].theme_config.primary_color
								: ''
						"
					></v-tabs-slider>

					<v-tab v-for="item in items" :key="item">
						{{ item }}
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab" class="pl-5">
					<v-tab-item>
						<v-container>
							<template v-for="questionnairesDetail in questionnairesDetails">
								<v-expansion-panels
									:key="questionnairesDetail.id"
									class="justify-start mb-10"
									v-if="questionnairesDetail.pairs.length != 0"
								>
									<h3 class="mb-3 float-left">
										{{ $t("questionnaire") }}: {{ questionnairesDetail.title }}
									</h3>
									<v-expansion-panel
										v-for="(pair, index) in questionnairesDetail.pairs"
										:key="index"
									>
										<v-expansion-panel-header>
											{{ index }}
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<v-simple-table>
												<template v-slot:default>
													<thead>
														<tr>
															<th class="text-left text-capitalize">
																{{ $t("question") }}
															</th>
															<th class="text-left text-capitalize">
																{{ $t("answer") }}
															</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="question in pair" :key="question.id">
															<td>{{ question.question }}</td>
															<td>{{ question.answer }}</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</template>
						</v-container>
					</v-tab-item>
					<v-tab-item v-if="hasProileTab"></v-tab-item>

					<v-tab-item v-if="hasHistoryTab">
						<History />
					</v-tab-item>

					<v-tab-item v-if="hasMessagesTab">
						<Messages :messages="messages" />
					</v-tab-item>
				</v-tabs-items>
			</v-container>
		</v-container>
	</div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapActions } from "vuex";
import History from "@/components/views/dashboard/members/id/partial/tabs/History";
import Messages from "@/components/views/dashboard/members/id/partial/tabs/Messages";
import BasicInfo from "@/components/shared/BasicInfo.vue";

export default {
	name: "detailed-view",

	data() {
		return {
			questionnairesDetails: null,
			tab: null,
			messages: [
				{
					date: 11,
					type: "type",
					message: "test",
					read: "now",
				},
			],
		};
	},

	computed: {
		isLifeChamps: function () {
			return (
				this.$store.getters["auth/user"]?.theme_config.key == "lifechamps"
			);
		},
		member: function () {
			return this.$store.getters["members/member"];
		},
		hasProileTab() {
			return this.$store.getters["auth/hasProileTab"];
		},
		hasMessagesTab() {
			return this.$store.getters["auth/hasMessagesTab"];
		},
		hasHistoryTab() {
			return this.$store.getters["auth/hasHistoryTab"];
		},
		items() {
			let tabs = [this.$t("health")];

			if (this.hasProileTab) {
				tabs.push(this.$t("profile"));
			}

			if (this.hasHistoryTab) {
				tabs.push(this.$t("history"));
			}

			if (this.hasMessagesTab) {
				tabs.push(this.$t("messages"));
			}

			return tabs;
		},
	},

	mounted() {
		this.getMember();
		this.getQuestionnairesDetails();

		this.$store.dispatch("logActivity", {
			tag: "memeber_profile_questionnaire_detail",
			type: 1,
			reference_id: this.$route.params.id,
			// To DO add uncomment this line after integrate this page with BE using questionnaire id
			// questionnaire_id: this.$route.params.questionnaireId,
		});
	},

	destroyed() {
		this.$store.dispatch("logActivity", {
			tag: "memeber_profile_questionnaire_detail",
			type: 2,
			reference_id: this.$route.params.id,
			// To DO add uncomment this line after integrate this page with BE using questionnaire id
			// questionnaire_id: this.$route.params.questionnaireId,
		});
	},

	methods: {
		...mapActions({
			getMember: "members/getMember",
		}),
		getQuestionnairesDetails() {
			this.$axios
				.get(`dashboard/get-questionnaires-by-user/${this.$route.params.id}`)
				.then((res) => {
					if (Object.keys(res.data.data)[0]) {
						this.questionnairesDetails = res.data.data;
					}
				});
		},
	},

	components: {
		Breadcrumbs,
		History,
		Messages,
		BasicInfo,
	},
};
</script>

<style lang="scss" src="@/assets/sass/views/dashboard/members/id.scss"></style>
